<template>
    <!-- /test/app-table/service-orders -->
    <div class="page-test-app-table-service-orders container">
        <div class="row">
            <div class="col-12">
                <app-table
                    class="service-orders-table"

                    title="Service Orders"

                    :cols="cols"
                    :rows="rows"

                    :loading="loading"
                ></app-table>
            </div>
        </div>
    </div>
</template>

<script>
import appTable from '@/components/app-table'

export default {
    components: {
        appTable,
    },

    data() {
        return {
            cols: [
                { key: 'description', title: 'Description',                                 },
                { key: 'start-date',  title: 'Start Date',                                  },
                { key: 'paid-until',  title: 'Paid Until',                                  },
                { key: 'status',      title: 'Status',      type: 'status',                 },
                { key: 'link',        title: 'Ext. Link',   type: 'link', icon: 'ext-link', },
            ],
            rows: [],

            loading: false,
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            const delay_in_ms = 1000

            this.loading = true

            setTimeout(() => {
                this.rows = [
                    {
                        description: 'Sunt labore magna',
                        'start-date': '2014-09-20 03:27:49',
                        'paid-until': '2014-09-20 03:27:49',
                        status: {
                            status: 'cancelled', // _table-tag-colors.scss
                            text: 'Cancelled',
                        },
                        link: 'https://github.com',
                    },
                    {
                        description: 'Sunt labore magna',
                        'start-date': '2014-09-20 03:27:49',
                        'paid-until': '2014-09-20 03:27:49',
                        status: {
                            status: 'cancelled', // _table-tag-colors.scss
                            text: 'Cancelled',
                        },
                    },
                    {
                        description: 'Sunt labore magna',
                        'start-date': '2014-09-20 03:27:49',
                        'paid-until': '2014-09-20 03:27:49',
                        status: {
                            status: 'cancelled', // _table-tag-colors.scss
                            text: 'Cancelled',
                        },
                        link: 'https://github.com',
                    },
                    {
                        description: 'Sunt labore magna',
                        'start-date': '2014-09-20 03:27:49',
                        'paid-until': '2014-09-20 03:27:49',
                        status: {
                            status: 'cancelled', // _table-tag-colors.scss
                            text: 'Cancelled',
                        },
                    },
                    {
                        description: 'Sunt labore magna',
                        'start-date': '2014-09-20 03:27:49',
                        'paid-until': '2014-09-20 03:27:49',
                        status: {
                            status: 'cancelled', // _table-tag-colors.scss
                            text: 'Cancelled',
                        },
                    },
                ]

                this.loading = false
            }, delay_in_ms)
        },
    }
}
</script>

<style lang="scss">
.page-test-app-table-service-orders {
    .service-orders-table {
        margin: 48px 0;

        @include table-cols-width((200px, 135px, 135px, 200px, 80px), true);

        .cols {
            .col {
                &:nth-child(5) {
                    justify-content: center;
                }
            }
        }

        .rows {
            .row {
                .col {
                    &:nth-child(5) {
                        justify-content: center;
                    }
                }
            }
        }
    }
}
</style>